<template>
    <div>
        <div ref="pdf" class="card-block superbill" v-if="superbill !== null">
            <h1>Statement for Insurance Reimbursement</h1>
            <hr />
            <h3>From</h3>
            <p>
                <b>{{ superbill.companyName }}</b>
            </p>
            <p>{{ superbill.companyStreet }}</p>
            <p>{{ superbill.companyCityStateZip }}</p>
            <hr />
            <div>
                <h3>To</h3>
                <p>
                    <b>{{ superbill.clientName }}</b>
                </p>
                <p>{{ superbill.clientStreet }}</p>
                <p>{{ superbill.clientCityStateZip }}</p>
                <p></p>
            </div>
            <hr />
            <div>
                <h3>Issued Date</h3>
                <p>{{ today }}</p>
            </div>
            <hr />
            <div>
                <h3>Client</h3>
                <p>
                    <b>{{ superbill.clientName }}</b>
                </p>
                <p>{{ superbill.clientPhone }}</p>
                <p>{{ superbill.clientEmail }}</p>
                <p>{{ superbill.clientDob }}</p>
            </div>

            <div>
                <h3>Provider</h3>
                <p>
                    <b>{{ superbill.providerName }}</b>
                </p>
                <p>{{ superbill.providerEmail }}</p>
                <p>{{ superbill.providerNpi }}</p>
            </div>

            <div>
                <h3>Insurance</h3>
                <p>Member: {{ superbill.clientMemberId }}</p>
                <p>Group ID: {{ superbill.clientGroupId }}</p>
            </div>

            <div>
                <h3>Practice</h3>
                <p>{{ superbill.companyName }}</p>
                <p>Tax ID: {{ superbill.companyTaxId }}</p>
                <p>NPI: {{ superbill.companyNpi }}</p>
            </div>

            <table v-if="servicesExist" class="bottom-20">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Service</th>
                        <th>Description</th>
                        <th>Fee</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="service in superbill.services" :key="service.serviceId">
                        <td>{{ service.serviceDate }}</td>
                        <td>{{ service.serviceCode }}</td>
                        <td>{{ service.serviceDescription }}</td>
                        <td>{{ service.serviceCharge }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="bottom-20">
                <p><strong>Total Charges:</strong> {{ superbill.totalCharges }}</p>
                <p><strong>Amount Paid:</strong> {{ superbill.paymentAmount }}</p>
            </div>
            <div>
                <button class="secondary" @click="getPdf">View as PDF</button>
                <button class="secondary" @click="savePdf">Save PDF</button>
            </div>
        </div>

        <div v-if="superbill === null && superbillLoaded">
            <p>There were no invoices found between {{ dateRangeDisplay }}.</p>
            <router-link :to="{ name: 'ClientBilling', params: { client_id: clientId } }"
                >Back to Member Billing</router-link
            >
        </div>
    </div>
</template>

<script>
    import { file, invoices } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';
    import { openPdf } from '@/util/pdf';

    export default {
        name: 'Superbill',
        props: {
            clientId: {
                type: [Number, String],
                required: true
            },
            startDate: {
                type: String,
                required: true
            },
            endDate: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                company: null,
                client: null,
                provider: null,
                invoices: [],
                totalPaid: 0,
                totalCharges: 0,
                amountDue: 0,
                superbill: null,
                superbillLoaded: false
            };
        },
        computed: {
            hasCompany() {
                return !!this.company;
            },
            hasClient() {
                return !!this.client;
            },
            hasProvider() {
                return !!this.provider;
            },
            today() {
                return dayjs().format('MM/DD/YYYY');
            },
            clientDateOfBirth() {
                return this.client.dob ? dayjs(this.client.dob).format('MM/DD/YYYY') : null;
            },
            services() {
                const serviceList = [];

                for (const invoice of this.invoices) {
                    for (const service of invoice.services) {
                        serviceList.push({
                            ...service,
                            invoice_id: invoice.id,
                            date: invoice.date
                        });
                    }
                }

                return serviceList;
            },
            dateRangeDisplay() {
                return `${dayjs(decodeURIComponent(this.startDate)).format('MM/DD/YYYY')} and ${dayjs(
                    decodeURIComponent(this.endDate)
                ).format('MM/DD/YYYY')}`;
            },
            servicesExist() {
                return this.superbill?.services && this.superbill.services.length > 0;
            }
        },
        methods: {
            async generateSuperbill() {
                try {
                    const res = await this.$api.get(
                        invoices.generateSuperbill(this.clientId, this.startDate, this.endDate)
                    );

                    if (res.status === 200) {
                        this.superbill = Array.isArray(res.data) ? null : res.data;
                    } else {
                        this.$toasted.error('There was an error generating superbill. Please try again later.');
                    }

                    this.superbillLoaded = true;
                } catch (err) {
                    this.$toasted.error('There was an error generating superbill. Please try again later.');
                }
            },
            async getPdf() {
                const res = await this.$api.get(invoices.getSuperbillPdf(this.clientId, this.startDate, this.endDate));

                if (res.status < 300) {
                    await openPdf(res.data, 'Superbill.pdf');
                }

                // window.open(
                //     `${process.env.VUE_APP_API_DOMAIN}${invoices.getSuperbillPdf(
                //         this.clientId,
                //         this.startDate,
                //         this.endDate
                //     )}`
                // );
            },
            formatDate(date) {
                return dayjs(date).format('MM/DD/YY');
            },
            async savePdf() {
                const res = await this.$api.post(
                    invoices.saveSuperbillPdf(this.clientId, this.startDate, this.endDate)
                );

                if (res.status < 300) {
                    this.$toasted.success("Successfully saved superbill to client's files");
                }
            }
        },
        async created() {
            await this.generateSuperbill();
        }
    };
</script>

<style scoped></style>
